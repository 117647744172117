<template>
  <div>
    <TailwindComponentsLoading
      v-if="isLoading"
      fullScreen
    ></TailwindComponentsLoading>
    <BaseTable
      :data="jobs"
      :columns="columns"
      :sortOrderProp="localSortOrder"
      :sortFieldProp="localSortField"
      :setPadding="setPadding"
      @sort="onSort"
    >
      <template #select-field="{ row, column }">
        <BaseCheckbox
          :value="selectedJobs?.some(selected => selected.jobId === row.jobId)"
          @check="event => addSelectedJob(row, event)"
        ></BaseCheckbox>
      </template>
      <template #selectHeader="{ row, column }">
        <BaseCheckbox :value="checkMark" @input="totalSelect"></BaseCheckbox>
      </template>
      <template #action-field="{ row, column }">
        <!-- <BaseActionDropdown
          :id="row.jobId"
          :position="'left'"
          :offset="{ x: 0, y: 25 }"
        >
          <ul
            tabindex="0"
            class="app-card__dropdown__menu dropdown-content app-card__dropdown__menu"
          >
            <li v-if="row['state'] == 'FINISHED'">
              <a
                v-if="row['type'] === 'Export Document'"
                :href="row['result']"
                target="_blank"
              >
                <i class="mdi mdi-download"></i>
                <span>{{ $t('Download') }}</span>
              </a>
              <NuxtLink
                v-else
                :to="getPathForNuxtLink(row)"
                @click="selectedJobType(row)"
              >
                <i class="mdi mdi-open-in-new"></i>
                <span>{{ $t('Open') }}</span>
              </NuxtLink>
            </li>

            <li v-if="row['state'] == 'FINISHED' && canUndo(row)">
              <span @click="undoJob(row)">
                <i class="mdi mdi-undo"></i>
                <span>{{ $t('Undo') }}</span>
              </span>
            </li>

            <li v-if="showRestart(row)">
              <span @click="restartJob(row)">
                <i class="mdi mdi-restart"></i>
                <span>{{ $t('Restart') }}</span>
              </span>
            </li>
            <li v-if="isAdmin">
              <label :for="'DrawerMetadata'" @click="openJobsMeta(row)">
                <i class="mdi mdi-database"></i>
                <span>{{ $t('Metadata') }}</span>
              </label>
            </li>
            <li v-if="showCancel(row)">
              <span @click="cancelJob(row)">
                <i class="mdi mdi-cancel"></i>
                <span>{{ $t('Cancel') }}</span>
              </span>
            </li>
          </ul>
        </BaseActionDropdown> -->
        <div
          class="app-card__dropdown app-card__dropdown--left content-left max-h-8"
        >
          <label tabindex="0" class="app-card__dropdown__btn">
            <i
              class="mdi mdi-dots-horizontal app-card__dropdown__btn__icon"
            ></i>
          </label>
          <ul tabindex="0" class="app-card__dropdown__menu dropdown-content">
            <li v-if="row['state'] == 'FINISHED'">
              <a
                v-if="row['type'] === 'Export Document'"
                :href="row['result']"
                target="_blank"
              >
                <i class="mdi mdi-download"></i>
                <span>{{ $t('Download') }}</span>
              </a>
              <NuxtLink
                v-else
                :to="getPathForNuxtLink(row)"
                @click="selectedJobType(row)"
              >
                <i class="mdi mdi-open-in-new"></i>
                <span>{{ $t('Open') }}</span>
              </NuxtLink>
            </li>

            <li v-if="row['state'] == 'FINISHED' && canUndo(row)">
              <span @click="undoJob(row)">
                <i class="mdi mdi-undo"></i>
                <span>{{ $t('Undo') }}</span>
              </span>
            </li>

            <li v-if="showRestart(row)">
              <span @click="restartJob(row)">
                <i class="mdi mdi-restart"></i>
                <span>{{ $t('Restart') }}</span>
              </span>
            </li>
            <li v-if="isAdmin">
              <label :for="'DrawerMetadata'" @click="openJobsMeta(row)">
                <i class="mdi mdi-database"></i>
                <span>{{ $t('Metadata') }}</span>
              </label>
            </li>
            <li v-if="showCancel(row)">
              <span @click="cancelJob(row)">
                <i class="mdi mdi-cancel"></i>
                <span>{{ $t('Cancel') }}</span>
              </span>
            </li>
          </ul>
        </div>
      </template>
      <template #downloadAction-field="{ row, column }">
        <!-- Check if job is not older than 13 days, otherwise it's expired -->
        <a
          v-if="
            row['type'] === 'Export Document' &&
            row['state'] === 'FINISHED' &&
            !isJobExpired(row['started'])
          "
          :href="row['result']"
          target="_blank"
        >
          <i class="mdi mdi-download"></i>
          <span>{{ $t('Download') }}</span>
        </a>
        <span v-else-if="row['type'] === 'Export Document'">
          <i class="mdi mdi-download-off"></i>
          <span>{{ $t('Expired') }}</span>
        </span>
      </template>
      <template #title-field="{ row, column }">
        <span class="truncate max-w-[140px]">
          <NuxtLink
            :to="getPathForNuxtLink(row)"
            @click="selectedJobType(row)"
            class="text-primary font-bold"
            >{{ truncate(row[column.field]) }}</NuxtLink
          >
        </span>
      </template>
      <template #modelName-field="{ row }">
        <a
          v-if="row.modelName"
          @click="navigateTo(row)"
          class="truncate max-w-xs font-bold"
        >
          {{ row.modelName }}</a
        >
        <span v-else class="truncate max-w-xs"
          >{{ $t('Name pending') }}...</span
        >
      </template>
      <template #jobtype-field="{ row, column }">
        {{ descriptionValue(row.jobImpl) }}
      </template>
      <template #server-field="{ row, column }">
        {{ getServerName(row[column.field]) }}
      </template>
      <template #start-field="{ row, column }">
        <BaseDate :value="row[column.field]" showTime />
      </template>
      <template #create-field="{ row, column }">
        <BaseDate :value="row[column.field]" showTime />
      </template>
      <template #state-field="{ row, column }">
        <div class="flex items-center">
          <!-- If job is in progress AND has more than 0% progress, show progress bar only -->
          <template v-if="isInProgress(row.state) && computedProgress(row) > 0">
            <div class="flex items-center">
              <!-- Progress bar container with percentage inside -->
              <div
                class="relative w-28 h-6 bg-white border border-notification-300 rounded-full overflow-hidden flex items-center justify-center shadow-sm"
              >
                <!-- Fixed blue circular indicator on the left -->
                <div
                  class="absolute left-0 top-0 bottom-0 bg-notification-300 rounded-full transition-all duration-300"
                  :style="{ width: `${computedProgress(row)}%` }"
                />
                <!-- Percentage text inside -->
                <span class="text-sm text-notification-200 font-medium z-10">
                  {{ computedProgress(row).toFixed(0) }}%
                </span>
              </div>
            </div>
          </template>

          <template v-else>
            <span :class="stateTag(row.state)">{{ row.state }}</span>
          </template>
        </div>
      </template>
      <template #description-field="{ row, column }">
        <BaseTooltip
          :tip="row[column.field]"
          small
          v-if="row[column.field]?.length > truncateSize"
        >
          <template v-slot:content>
            {{ truncate(row[column.field]) }}
          </template>
        </BaseTooltip>

        <span v-else class="truncate max-w-xs">{{
          descriptionValue(row[column.field])
        }}</span>
      </template>
      <template #afterRows>
        <tr
          v-if="total > jobs?.length && enableEndlessScrolling && !localJobId"
          class="animate-pulse h-16 sm:h-[66px]"
        >
          <td
            class="whitespace-nowrap py-2 sm:py-4 px-2 sm:pl-4 sm:pr-3 text-sm"
          >
            <div class="bg-gray-300 rounded w-20 sm:w-32 h-4"></div>
          </td>
          <td class="whitespace-nowrap px-2 sm:px-3 py-2 sm:py-4 text-sm">
            <div class="bg-gray-300 rounded w-6 sm:w-8 h-4"></div>
          </td>
          <td class="whitespace-nowrap px-2 sm:px-3 py-2 sm:py-4 text-sm">
            <div class="bg-gray-300 rounded w-6 sm:w-8 h-4"></div>
          </td>
          <td class="whitespace-nowrap px-2 sm:px-3 py-2 sm:py-4 text-sm">
            <div class="bg-gray-300 rounded w-6 sm:w-8 h-4"></div>
          </td>
          <td class="relative py-2 sm:py-4 px-2 sm:pl-3 sm:pr-4 text-sm"></td>
        </tr>
      </template>
    </BaseTable>
  </div>
</template>

<script>
import jobTypeConfig from '~/assets/job-types.json'
import DrawerMetadata from '~/components/Drawer/Metadata.vue'
import debounce from 'lodash/debounce'

export default {
  name: 'JobsTable',
  props: {
    // Filter jobs by specific types (e.g., 'Upload', 'Export')
    filterJobTypes: {
      type: Array,
      default: () => [],
    },
    // Collection ID to filter jobs by
    collectionId: {
      type: Number,
      default: null,
    },
    // Whether to show the selection column
    showSelectionColumn: {
      type: Boolean,
      default: true,
    },
    // Whether to show the type filter
    showTypeFilter: {
      type: Boolean,
      default: true,
    },
    // Whether to filter by the current user
    filterByUser: {
      type: Boolean,
      default: true,
    },
    // User ID to filter jobs by
    userId: {
      type: Number,
      default: null,
    },
    // Job ID to filter jobs by
    jobId: {
      type: Number,
      default: null,
    },
    // Status filter passed from parent
    status: {
      type: String,
      default: 'ALL',
    },
    // Sort field passed from parent
    sortField: {
      type: String,
      default: 'jobId',
    },
    // Sort order passed from parent
    sortOrder: {
      type: String,
      default: 'desc',
    },
    // Current page passed from parent
    currentPage: {
      type: Number,
      default: 1,
    },
    // Items per page passed from parent
    itemsPerPage: {
      type: Number,
      default: 24,
    },
    // Job types filter passed from parent
    jobTypes: {
      type: Array,
      default: undefined,
    },
    // Default sort field
    defaultSortField: {
      type: String,
      default: 'jobId',
    },
    // Default sort order
    defaultSortOrder: {
      type: String,
      default: 'desc',
    },
    // Default status filter
    defaultStatus: {
      type: String,
      default: 'ALL',
    },
    // Custom columns configuration
    customColumns: {
      type: Array,
      default: null,
    },
    // Container element for endless scrolling (CSS selector)
    scrollContainer: {
      type: String,
      default: null, // Default to window if null
    },
    // Enable endless scrolling
    enableEndlessScrolling: {
      type: Boolean,
      default: true,
    },
    setPadding: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      jobOptions: [],
      jobItems: [],
      isLoadingSmall: false,
      jobs: [],
      isLoading: false,
      isReady: false,
      offset: 0,
      total: 0,
      selectedJobs: [],
      lastSelectedIndex: null,
      truncateSize: 30,
      checkMark: false,
      selectAllJobs: false,
      columns: [
        {
          field: 'action',
          label: this.$t('Select'),
          select: true,
          visible: true,
          display: true,
          custom: true,
          slotName: 'select-field',
          customHeader: true,
          slotNameHeader: 'selectHeader',
        },
        {
          field: 'colId',
          label: this.$t('Collection ID'),
          sortable: true,
          visible: false,
        },
        {
          field: 'docId',
          label: this.$t('Document ID'),
          sortable: true,
          visible: false,
        },
        {
          field: 'docTitle',
          label: this.$t('Title'),
          numeric: false,
          searchable: false,
          sortable: false,
          visible: true,
          display: true,
          custom: true,
          slotName: 'title-field',
        },
        {
          field: 'jobImpl',
          label: this.$t('Job'),
          numeric: false,
          sortable: true,
          visible: true,
          display: true,
          customHeader: this.showTypeFilter,
          slotNameHeader: 'type-field',
          custom: true,
          slotName: 'jobtype-field',
        },
        {
          field: 'userName',
          label: this.$t('User'),
          sortable: true,
          visible: true,
          display: true,
        },
        {
          field: 'moduleUrl',
          label: this.$t('Server'),
          numeric: true,
          sortable: true,
          display: false,
          custom: true,
          slotName: 'server-field',
        },
        {
          field: 'state',
          label: this.$t('State'),
          sortable: true,
          custom: true,
          display: true,
          slotName: 'state-field',
        },
        {
          field: 'created',
          label: this.$t('Date Created'),
          numeric: false,
          sortable: true,
          visible: true,
          display: true,
          custom: true,
          slotName: 'create-field',
        },
        {
          field: 'started',
          label: this.$t('Date Started'),
          numeric: false,
          sortable: true,
          visible: true,
          display: true,
          custom: true,
          slotName: 'start-field',
        },
        {
          field: 'description',
          label: this.$t('Description'),
          sortable: true,
          visible: true,
          display: true,
          custom: true,
          slotName: 'description-field',
        },
        {
          field: 'pages',
          label: this.$t('Nr. of'),
          width: 2,
          sortable: true,
          visible: true,
          display: true,
        },
        {
          field: 'jobId',
          label: 'Job-ID',
          sortable: true,
          display: true,
        },
        {
          field: 'action',
          label: this.$t('Actions'),
          numeric: false,
          sortable: false,
          visible: true,
          display: true,
          custom: true,
          slotName: 'action-field',
        },
      ],
      statusOptions: [
        {
          name: this.$t('State (All)'),
          value: 'ALL',
        },
        {
          name: this.$t('FINISHED'),
          value: 'FINISHED',
        },
        { name: this.$t('RUNNING'), value: 'RUNNING' },
        { name: this.$t('CREATED'), value: 'CREATED' },
        { name: this.$t('CANCELED'), value: 'CANCELED' },
        { name: this.$t('FAILED'), value: 'FAILED' },
      ],
      localStatus: this.status,
      localCurrentPage: this.currentPage,
      localItemsPerPage: this.itemsPerPage,
      localSortField: this.sortField,
      localSortOrder: this.sortOrder,
      localUserId: this.userId,
      localJobTypes: this.jobTypes,
      localJobId: this.jobId,
      localJobTypeFilter: 'ALL',
      localFilterByUser: this.filterByUser,
      jobTypeOptions: [
        {
          name: this.$t('Job Type (All)'),
          value: 'ALL',
        },
      ],
      isLoadingMore: false,
      hasMoreItems: true,
      scrollElement: null,
    }
  },
  setup() {
    const jobsApi = useJobs()
    const { userProfile } = useKeycloak()

    return {
      jobsApi,
      userProfile,
      isJobExpired: startedDate => {
        if (!startedDate) return true

        const jobDate = new Date(startedDate)
        const currentDate = new Date()

        // Calculate the difference in milliseconds
        const differenceMs = currentDate - jobDate

        // Convert to days (86400000 = 24 * 60 * 60 * 1000)
        const differenceDays = differenceMs / 86400000

        // Return true if the difference is more than 13 days
        return differenceDays > 13
      },
    }
  },
  computed: {
    isAdmin() {
      return this.userProfile.IsAdmin
    },
    userUserId() {
      return this.userProfile.UserId
    },
  },
  watch: {
    // Watch for changes in props from parent component
    status(newVal) {
      this.localStatus = newVal
      this.getJobs()
    },

    sortField(newVal) {
      this.localSortField = newVal
      this.getJobs()
    },
    sortOrder(newVal) {
      this.localSortOrder = newVal
      this.getJobs()
    },
    userId(newVal) {
      this.localUserId = newVal
      // Don't call getJobs() here, it will be called by the debounced method
      this.debouncedGetJobs()
    },
    jobId(newVal) {
      this.localJobId = newVal
      this.debouncedGetJobs()
    },
    filterByUser(newVal) {
      this.localFilterByUser = newVal
      // Don't call getJobs() here, it will be called by the debounced method
      this.debouncedGetJobs()
    },
    jobTypes(newVal) {
      this.localJobTypes = newVal
      this.debouncedGetJobs()
    },
    // Watch for changes in local state
    localStatus() {
      this.getJobs()
    },
    localItemsPerPage() {
      this.offset = (this.localCurrentPage - 1) * this.localItemsPerPage
      this.getJobs()
    },
    selectedJobs: {
      handler(newVal) {
        this.$emit('selected-jobs-changed', newVal)
      },
      deep: true,
    },
    collectionId: {
      handler(newVal) {
        console.log('🚀 ~ handler ~ newVal:', newVal)
        if (newVal !== null) {
          this.getJobs()
        }
      },
      immediate: true,
    },
    filterJobTypes: {
      handler(newVal) {
        if (newVal.length > 0 && !this.localJobTypes) {
          this.localJobTypes = newVal
          this.getJobs()
        }
      },
      immediate: true,
    },
  },
  mounted() {
    // Apply visibility based on props
    if (!this.showSelectionColumn) {
      const selectColumn = this.columns.find(
        col => col.field === 'action' && col.select
      )
      if (selectColumn) selectColumn.display = false
    }

    // Apply admin-only columns visibility
    if (this.columns.length > 5) {
      const userNameColumn = this.columns.find(col => col.field === 'userName')
      const moduleUrlColumn = this.columns.find(
        col => col.field === 'moduleUrl'
      )
      if (userNameColumn) userNameColumn.display = this.isAdmin
      if (moduleUrlColumn) moduleUrlColumn.display = this.isAdmin
    }

    // If there are custom columns, use them instead of default columns
    if (this.customColumns && this.customColumns.length > 0) {
      this.columns = this.customColumns
    }

    // Initialize job type options
    this.getFilteredOption()
    this.getJobs()
    this.jobTypeOptions = this.jobTypeOptions.concat(
      this.jobOptions.map(option => ({
        name: option.name,
        value: option.value,
      }))
    )

    // Add scroll event listener for endless scrolling if enabled
    if (this.enableEndlessScrolling) {
      this.setupScrollListener()
    }

    // Add resize listener for truncate size
    window.addEventListener('resize', this.setTruncateSize)
    this.setTruncateSize()
  },
  beforeDestroy() {
    // Remove event listeners when component is destroyed
    this.removeScrollListener()
    window.removeEventListener('resize', this.setTruncateSize)
  },
  methods: {
    setTruncateSize() {
      const width = window.innerWidth
      if (width < 768) {
        this.truncateSize = 15
      } else if (width < 1024) {
        this.truncateSize = 20
      } else {
        this.truncateSize = 30
      }
    },
    isInProgress(state) {
      return ['RUNNING', 'CREATED', 'WAITING'].includes(state)
    },
    computedProgress(job) {
      if (job.progress && job.totalWork && job.totalWork > 0) {
        return (job.progress / job.totalWork) * 100
      }
      return 0
    },
    getServerName(url) {
      if (!url) return ''
      try {
        const parsedUrl = new URL(url)
        return parsedUrl.hostname
      } catch (e) {
        return url
      }
    },
    openJobsMeta(row) {
      const slideOver = {
        slideOver: 'DrawerMetadata',
        slideContent: {
          component: DrawerMetadata,
          data: {},
          props: {
            job: row,
            isJob: true,
          },
        },
      }
      this.$bus.emit('slideOver', slideOver)
    },
    totalSelect(value) {
      this.checkMark = !this.checkMark
      this.selectAllJobs = this.checkMark

      if (this.selectAllJobs) {
        // Select all loaded jobs
        this.jobs.forEach(job => {
          if (
            !this.selectedJobs.some(selected => selected.jobId === job.jobId)
          ) {
            this.selectedJobs.push(job)
          }
        })

        // Emit event to parent to handle selecting all jobs, including those not loaded yet
        this.$emit('select-all-jobs', true, this.total)
      } else {
        // Deselect all jobs
        this.selectedJobs = []
        this.$emit('select-all-jobs', false)
      }

      // Emit selected jobs for parent components
      this.$emit('selected-jobs-changed', this.selectedJobs)
    },
    getFilteredOption(searchValue = '') {
      // Use the jobTypeConfig directly since it already has the correct format
      // with name and value properties
      this.jobOptions = [...jobTypeConfig]

      // Filter options based on search value if provided
      if (searchValue) {
        this.jobItems = this.jobOptions
          .filter(option =>
            option.name.toLowerCase().includes(searchValue.toLowerCase())
          )
          .map(item => item.name)
      } else {
        this.jobItems = this.jobOptions.map(item => item.name)
      }
    },
    convertJobsToNames(jobTypes) {
      if (!jobTypes) return []

      let result = []
      jobTypes.forEach(jobType => {
        const typeFound = this.jobOptions.find(val => val.value === jobType)
        if (typeFound) {
          result.push(typeFound.name)
        }
      })
      return result
    },
    getItems(items) {
      // For command palette, we need just the names
      return items.map(item => item.name || item)
    },
    setJobFilter(option) {
      // If the filter is empty
      if (option.length === 0) {
        this.localJobTypes = undefined
        this.getJobs()
        this.$emit('job-types-changed', this.localJobTypes)
        return
      }

      // Convert selected option names back to values
      this.localJobTypes = option.map(name => {
        const found = this.jobOptions.find(opt => opt.name === name)
        return found ? found.value : name
      })

      this.getJobs()

      // Emit selected job types for parent components
      this.$emit('job-types-changed', this.localJobTypes)
    },
    handleJobTypeChange(value) {
      this.localJobTypeFilter = value
      this.getJobs()
    },
    async getCount() {
      let data
      let status = this.localStatus === 'ALL' ? undefined : this.localStatus
      try {
        data = await this.jobsApi.fetchJobsCount({
          filterByUser: this.localFilterByUser,
          jobTypes: this.localJobTypes,
          status: status,
          collId: this.collectionId,
          userid: this.localUserId,
        })
      } catch (err) {
        this.$sentry.captureException(err)
        this.total = 0
        return
      }
      this.total = parseInt(data)
    },
    async getJobs(isLoadingMore = false) {
      if (!isLoadingMore) {
        this.isLoadingSmall = true
      } else {
        this.isLoadingMore = true
      }

      let data
      this.isLoading = true
      let status = this.localStatus === 'ALL' ? undefined : this.localStatus
      try {
        if (
          this.localJobId !== null &&
          this.localJobId !== undefined &&
          this.localJobId !== ''
        ) {
          this.total = 1
          data = await this.jobsApi.fetchJob({
            jobId: this.localJobId,
          })
          this.jobs = [data]
        } else {
          data = await this.jobsApi.fetchJobs({
            index: this.offset,
            nValues: this.localItemsPerPage,
            status: status,
            collId: this.collectionId,
            sortDirection: this.localSortOrder.toUpperCase(),
            sortColumn: this.localSortField,
            userid: this.localUserId,
            filterByUser: this.localFilterByUser,
            jobTypes: this.localJobTypes,
          })

          if (isLoadingMore) {
            // Append new jobs to existing ones for endless scrolling
            this.jobs = [...this.jobs, ...data]
            // Check if we've reached the end
            this.hasMoreItems = data.length === this.localItemsPerPage
          } else {
            this.jobs = data
            this.hasMoreItems = true
          }
        }
      } catch (err) {
        this.$sentry.captureException(err)
        this.isLoading = false
        this.isLoadingSmall = false
        this.isLoadingMore = false
        await this.getCount()
        return
      } finally {
        this.isLoadingSmall = false
        this.isLoadingMore = false
        if (this.jobs == undefined) {
          this.jobs = []
        }
      }

      // Emit jobs loaded event for parent components
      this.$emit('jobs-loaded', this.jobs)

      const unfinishedJobs = this.jobs.filter(
        job =>
          job.state == 'RUNNING' ||
          job.state == 'CREATED' ||
          job.state == 'WAITING'
      )
      if (unfinishedJobs.length > 0) {
        // this.startPolling(unfinishedJobs)
      }

      this.isReady = true
      this.$nextTick(() => {
        this.isLoading = false
        this.isLoadingSmall = false
      })
      await this.getCount()

      // If count is greater than 0 but no jobs are shown, try to reset offset and fetch again
      if (this.total > 0 && this.jobs.length === 0 && this.offset > 0) {
        this.offset = 0
        this.localCurrentPage = 1
        this.getJobs()
      }
    },
    async cancelJob(job, showSuccessToast = true) {
      let notification
      try {
        await this.jobsApi.updateJob({
          jobId: job.jobId,
        })
        if (showSuccessToast) {
          notification = {
            id: Date.now(),
            type: 'success',
            message: this.$t('Job canceled'),
          }
        }
      } catch (err) {
        notification = {
          id: Date.now(),
          type: 'error',
          message: this.$t('Error canceling job'),
        }
      }
      if (notification) {
        this.$bus.emit('notification', notification)
      }
      this.getJobs()
    },
    async restartJob(job, showSuccessToast = true) {
      let notification
      try {
        await this.jobsApi.restartJob({
          jobId: job.jobId,
        })
        if (showSuccessToast) {
          notification = {
            id: Date.now(),
            type: 'success',
            message: this.$t('Job restarted'),
          }
        }
      } catch (err) {
        notification = {
          id: Date.now(),
          type: 'error',
          message: this.$t('Error restarting job'),
        }
      }
      if (notification) {
        this.$bus.emit('notification', notification)
      }
      this.getJobs()
    },
    async undoJob(job) {
      let notification
      try {
        await this.jobsApi.undoJob({
          jobId: job.jobId,
        })
        notification = {
          id: Date.now(),
          type: 'success',
          message: this.$t('Job undone'),
        }
      } catch (err) {
        notification = {
          id: Date.now(),
          type: 'error',
          message: this.$t('Error undoing job'),
        }
      }
      this.$bus.emit('notification', notification)
      this.getJobs()
    },
    canUndo(job) {
      return job.canUndo
    },
    addSelectedJob(job, event) {
      const index = this.jobs.findIndex(j => j.jobId === job.jobId)
      if (event.shiftKey && this.lastSelectedIndex !== null) {
        const start = Math.min(index, this.lastSelectedIndex)
        const end = Math.max(index, this.lastSelectedIndex)
        const jobsToAdd = this.jobs.slice(start, end + 1)
        jobsToAdd.forEach(j => {
          if (!this.selectedJobs.some(selected => selected.jobId === j.jobId)) {
            this.selectedJobs.push(j)
          }
        })
      } else {
        const selectedIndex = this.selectedJobs.findIndex(
          j => j.jobId === job.jobId
        )
        if (selectedIndex === -1) {
          this.selectedJobs.push(job)
        } else {
          this.selectedJobs.splice(selectedIndex, 1)
        }
      }
      this.lastSelectedIndex = index

      // Emit selected jobs for parent components
      this.$emit('selected-jobs-changed', this.selectedJobs)
    },
    onSort(value) {
      this.localSortField = value.field
      this.localSortOrder = value.order
      this.getJobs()
    },
    getPathForNuxtLink(item) {
      if (item.docId == -1) {
        return ''
      } else {
        if (item.pages) {
          const pageNumber = item.pages.split(/[, -]+/)[0]
          return this.localePath(
            `/collection/${item.colId}/doc/${item.docId}/edit?pageid=${pageNumber}`
          )
        } else {
          return `/collection/${item.colId}/doc/${item.docId}`
        }
      }
    },
    selectedJobType(item, openInNewTab = false) {
      if (item.docId == -1) {
        let notification

        notification = {
          message: this.$t('Jobs does not reference document'),
          type: 'error',
        }

        this.$bus.emit('notification', notification)
        return
      }

      if (item.docTitle === 'Deleted Document') {
        let notification

        notification = {
          message: this.$t('Document has been deleted'),
          type: 'error',
        }

        this.$bus.emit('notification', notification)
      }
    },
    handleMouseDown(event, item) {
      if (event.button === 1 || event.button === 2) {
        this.selectedJobType(item, true)
      }
    },
    truncate(str, n = this.truncateSize) {
      return str && str.length > n ? str.slice(0, n - 1) + '...' : str
    },
    showCancel(job) {
      return (
        job.state === 'RUNNING' ||
        job.state === 'CREATED' ||
        job.state === 'WAITING'
      )
    },
    showRestart(job) {
      return (
        job.state === 'CANCELED' ||
        job.state === 'FAILED' ||
        job.state === 'FINISHED'
      )
    },
    stateTag(state) {
      switch (state) {
        case 'FINISHED':
          return [
            'inline-block',
            'px-3',
            'py-1',
            'text-xs',
            'font-semibold',
            'rounded-full',
            'text-successful-700',
            'bg-successful-100/80',
          ].join(' ')
        case 'FAILED':
          return [
            'inline-block',
            'px-3',
            'py-1',
            'text-xs',
            'font-semibold',
            'rounded-full',
            'text-danger',
            'bg-danger-100/80',
          ].join(' ')
        default:
          return [
            'inline-block',
            'px-3',
            'py-1',
            'text-xs',
            'font-semibold',
            'rounded-full',
            'text-[#193060]',
            'bg-[#193060]/[.06]',
          ].join(' ')
      }
    },
    descriptionValue(value) {
      if (!value) return ''
      const found = jobTypeConfig.find(type => type.value === value)
      if (found) {
        return this.$t(found.name)
      }
      return value
    },
    setupScrollListener() {
      if (this.scrollContainer) {
        // Find the custom scroll container
        const container = document.querySelector(this.scrollContainer)
        if (container) {
          this.scrollElement = container
          container.addEventListener('scroll', this.handleScroll)
        } else {
          console.warn(
            `Scroll container ${this.scrollContainer} not found, using window instead`
          )
          this.scrollElement = window
          window.addEventListener('scroll', this.handleScroll)
        }
      } else {
        // Use window as default scroll container
        this.scrollElement = window
        window.addEventListener('scroll', this.handleScroll)
      }
    },
    removeScrollListener() {
      if (this.scrollElement) {
        this.scrollElement.removeEventListener('scroll', this.handleScroll)
      }
    },
    handleScroll() {
      // Skip if endless scrolling feature is disabled or already loading more items or there are no more items to load
      if (
        !this.enableEndlessScrolling ||
        this.isLoadingMore ||
        !this.hasMoreItems
      )
        return

      let scrollTop, windowHeight, documentHeight, bottomReached

      if (this.scrollElement === window) {
        // Window scrolling
        scrollTop = window.scrollY
        windowHeight = window.innerHeight
        documentHeight = document.documentElement.scrollHeight
      } else {
        // Custom container scrolling
        scrollTop = this.scrollElement.scrollTop
        windowHeight = this.scrollElement.clientHeight
        documentHeight = this.scrollElement.scrollHeight
      }

      bottomReached = scrollTop + windowHeight >= documentHeight - 50

      if (!bottomReached) return

      // Calculate the next page number within bounds
      const sanitizedValue = Math.min(
        this.localCurrentPage + 1,
        Math.ceil(Math.max(this.total, 1) / this.localItemsPerPage)
      )

      // Skip if we're already on the last page
      if (sanitizedValue <= this.localCurrentPage) return

      this.localCurrentPage = sanitizedValue
      const oldOffset = this.offset
      this.offset = (sanitizedValue - 1) * this.localItemsPerPage

      // Skip if offset hasn't changed
      if (oldOffset === this.offset) return

      // Load more jobs
      this.$nextTick(() => {
        this.getJobs(true)
      })
    },
  },
  created() {
    this.debouncedGetJobs = debounce(() => {
      this.getJobs()
    }, 300)
  },
}
</script>
